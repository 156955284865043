<template>
<div>
  <bar title="true" closeActivation="true"/>

  <v-content>
    <message/>
<v-container>
  <v-layout row wrap>
    
    <v-flex xs12 >
      <v-sheet
        color="red"
        class="pa-2 text-center"
        v-show="!sended"
      >
        <v-subheader>Eliminación de la cuenta</v-subheader>

        <v-dialog v-model="dialog" persistent max-width="400px">
            <template v-slot:activator="{ on }">
              <v-btn color="error" v-on="on" large >Eliminar</v-btn>
            </template>
            <v-card>
              <v-card-title row justify-center>
                <h3 class="headline text-md-center" v-show="!sended">Eliminación de la cuenta</h3>
                <h3 class="headline text-md-center" v-show="sended">Cuenta eliminada correctamente</h3>
              </v-card-title>
              <v-form ref="form">
                <v-card-text v-show="!sended">
                  Si deseas eliminar tu cuenta pulsa OK, tu email y tu progreso serán eliminados por completo de la base de datos de poemsandlearn, no podrás recuperar tus datos
                </v-card-text>
                <v-card-text v-show="sended">
                  Has eliminado definitivamente tu cuenta, gracias por haber confiado en nosotros
                </v-card-text>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-show="!sended" color="warning" text @click="dialog = false">Cancelar</v-btn>
                <v-btn v-show="!sended" color="error" :loading="loading" @click="deleteUser">OK</v-btn>
                <v-btn v-show="sended" color="success" @click="dialog = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

      </v-sheet>
    </v-flex>
  
  </v-layout>
</v-container>
  </v-content>

  <pl-footer/>
</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import footer from '../components/appComponents/Footer.vue'

export default {
  name: 'Delete',
  data() {
    return {
      sended: false,
      hash: '',
      dialog: false,
      loading: false
    }
  },
  components: {
    'pl-footer': footer,
    message,
    bar
  },
  mounted(){
  	if (this.$route.params.hash.length !== 6){
    	this.start();
  	}
  	this.hash = this.$route.params.hash;
  	this.$store.state.playMode = false;
    this.$store.state.loginView = false;
    this.$store.state.logoutView = false;
    this.$store.state.registerView = true;
    this.$root.$emit('changeMessage', { show : true , text : 'Pulsa eliminar para borrar definitivamente tu cuenta, no podrás recuperar tus datos' } )
  },
  methods: {
    start: function () {
      this.$store.state.currentRoute = '/';
      this.$router.push('/');
    },
    deleteUser: function () {
      this.loading = true;
      this.$store.commit('recalculateCaptcha')
      this.$http.delete(this.$store.state.urlApi+'user', { 
        params: {
            hash: this.hash
        }, 
        headers: {
            Accept: 'application/json',
            'Captcha-Authorization': this.$store.state.captchaTokenResult
      }}).then(response => {
        this.sended = true
        this.$root.$emit('changeMessage', { show : true , text : 'Has eliminado definitivamente tu cuenta, gracias por haber confiado en poemsandlearn' } )
      }, response => {
          this.dialog = false
          this.loading = false
          let message = 'Ocurrió un error inesperado, inténtelo más tarde'
          if (response.body.error === 'token_is_expired' ) {
            message = 'Han pasado más de 15 minutos desde que te enviamos el email. Vuelve a solicitar el email y completa la acción antes de 15 minutos'
          } else if(response.body.error === 'token_does_not_exist'){
            message = 'No ha sido necesario realizar la acción, es posible que ya la realizaras en el pasado'
          }
          this.$root.$emit('changeSnackbar', { show : true , text : message, timeout: 4000, type: 'warning' } )
      });
    }
  }
}
</script>

<style>

</style>
